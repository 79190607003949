import fbLogo from '../images/f_logo_RGB-Blue_1024.png';
import twitterLogo from '../images/2021 Twitter logo - blue.png';
import igLogo from '../images/Instagram_Glyph_Gradient.png';
import logo from '../images/Hurricane-logo.png'

function Footer() {
    return (
        <footer className="bg-black w-full text-white mt-2 mb-0 bottom-0">
            <div className="h-full container mx-auto px-4 my-4">
                <div className="md:flex md:flex-wrap justify-center">
                    <div className="wd-full md:w-2/12 px-4">
                    <div>
                        <div className="mt-6">
                            <img className="img-fluid" src={logo} alt=""/>
                        </div>
                        <div className="flex mt-2 items-center">
                            <a href="https://www.facebook.com/hurricaneindustrialequipment"><img className="mx-2" src={fbLogo} alt="Facebook" style={{marginTop: '10px', maxWidth: '25px', height: 'auto'}}/></a>
                            <a href="https://twitter.com/hurricaneie"><img className="mx-2" src={twitterLogo} alt="Twitter" style={{marginTop: '10px', marginLeft: '8px', maxWidth: '25px', height: 'auto'}}/></a>
                            <a href="https://www.instagram.com/hurricaneforklifts/?hl=en"><img className="mx-2" src={igLogo} alt="Instagram" style={{marginTop: '10px', marginLeft: '8px', maxWidth: '25px', height: 'auto'}}/></a>
                        </div>
                    </div>
                    </div>
                    {window.innerWidth > 640 && (<div className="relative w-.75 items-center my-2.5 mx-3"><div className="h-full border border-hurricane-orange bg-hurricane-orange items-center"/></div>)}
                    <div className="mx-4">
                        <h3 className="fw-bold text-wrap text-hurricane-orange uppercase font-bold text-2xl">Nova Scotia</h3>
                        <p className="text-white text-wrap">
                            Tel: 902-468-8518<br/>188 Joseph Zatzman Drive<br/>Dartmouth, NS. B3B 1N4
                        </p>
                    </div>
                    <div className="mx-4">
                        <h3 className="fw-bold text-wrap text-hurricane-orange uppercase font-bold text-2xl">Newfoundland</h3>
                        <p className="text-white text-wrap">
                            Tel: 709-745-7442<br/>85 Glencoe Drive<br/>Mount Pearl, NL. A1N 4S6
                        </p>
                    </div>
                    <div className="mx-4">
                        <h3 className="fw-bold text-wrap text-hurricane-orange uppercase font-bold text-2xl">New Brunswick</h3>
                        <p className="text-white text-wrap">
                            Tel: 506-657-5804<br/>280 Grandview Ave<br/>Saint John, NB. E2J 4N1
                        </p>
                    </div>
                    <div className="text-white">
                        <div className="w-full">
                            <h3 className="font-bold bg-hurricane-orange text-2xl w-full uppercase">Shop</h3>
                            <ul>
                                <li>
                                <a href="/hyundai">Hyundai</a>
                                </li>
                                <li>
                                <a href="/linde">Linde</a>
                                </li>
                                <li>
                                <a href="/loadlifter">Load Lifter</a>
                                </li>
                                <li>
                                <a href="/bluegiant">Blue Giant</a>
                                </li>
                                <li>
                                <a href="/preowned">Pre-Owned</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;