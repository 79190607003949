import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function HyundaiConstructionEquipmentPage() {
    const { productId } = useParams();
    const [iframeSrc, setIframeSrc] = useState('');
    const proxyUrl = 'https://www.hurricanelift.com:5000/proxy';
    const realProductId = productId.replace(/--/g, '/');

    // Construct the target URL based on the productId
    const targetUrl = `https://na.hd-hyundaice.com/${realProductId}`;

    useEffect(() => {
        fetch(`${proxyUrl}?url=${encodeURIComponent(targetUrl)}`)
            .then((response) => response.text())
            .then((content) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');

                // Remove unwanted elements from the fetched content
                const header = doc.querySelector('header');
                const footer = doc.querySelector('footer');
                const nav = doc.querySelector('nav');
                const next = doc.querySelector('.next-prev-equipment');
                //const scripts = doc.querySelectorAll('script');
                //const styles = doc.querySelectorAll('link[rel="stylesheet"]');

                if (header) header.remove();
                if (footer) footer.remove();
                if (nav) nav.remove();
                if (next) next.remove();
                //scripts.forEach(script => script.remove());
                //styles.forEach(style => style.remove());

                const elementsWithUrls = doc.querySelectorAll('[src], [href], [data-src], [data-bg-image]');
                elementsWithUrls.forEach(el => {
                    ['src', 'href', 'data-src', 'data-bg-image'].forEach(attr => {
                        const attrValue = el.getAttribute(attr);
                        if (attrValue) {
                            // If the attribute is data-bg-image, extract the URL inside url(...)
                            if (attr === 'data-bg-image') {
                                const urlMatch = attrValue.match(/url\((['"]?)(.*?)\1\)/);
                                if (urlMatch && urlMatch[2]) {
                                    const imageUrl = urlMatch[2];
                                    const absoluteImageUrl = new URL(imageUrl, targetUrl).href;
                                    // Update the attribute with the absolute URL
                                    el.setAttribute(attr, `url('${absoluteImageUrl}')`);
                                }
                            } else {
                                el.setAttribute(attr, new URL(attrValue, targetUrl).href);
                            }
                        }
                    });
                });

                const imgTags = doc.querySelectorAll('img[data-src]');
                imgTags.forEach(img => {
                    img.setAttribute('src', img.getAttribute('data-src'));
                    img.removeAttribute('data-src');
                    // Optionally remove lazyload class
                    img.classList.remove('lazyload');
                });

                // Handle elements with data-bg-image attributes
                const elementsWithDataBgImage = doc.querySelectorAll('[data-bg-image]');
                elementsWithDataBgImage.forEach(el => {
                    const bgImage = el.getAttribute('data-bg-image');
                    if (bgImage) {
                        // Set the style background-image property
                        el.style.backgroundImage = bgImage;
                        // Remove the data-bg-image attribute
                        el.removeAttribute('data-bg-image');
                        // Remove lazyload class
                        el.classList.remove('lazyload');
                    }
                });

                // Serialize the modified DOM back to a string
                const serializer = new XMLSerializer();
                const modifiedContent = serializer.serializeToString(doc);

                // Create a blob URL to use in the iframe
                const blob = new Blob([modifiedContent], { type: 'text/html' });
                const blobUrl = URL.createObjectURL(blob);
                setIframeSrc(blobUrl);
            })
            .catch((error) => {
                console.error(`Error fetching content: ${error.message}`);
            });
    }, [productId]);

    return (
        <iframe
            title="Hyundai Construction Equipment"
            src={iframeSrc}
            style={{ minHeight: '80vh', width: '100%', border: 'none' }}
            className="mx-auto my-10 mt-30"
        ></iframe>
    );
}

export default HyundaiConstructionEquipmentPage;
