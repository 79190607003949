import { useEffect } from "react";
import ReactDOM from "react-dom";

function PromoModal({ onClose, children }) {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return ReactDOM.createPortal(
        // Attach onClick={onClose} to the container that covers the entire viewport
        <div
            className="fixed inset-0 flex items-center justify-center z-50"
            onClick={onClose}
        >
            {/* The overlay */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* The modal content */}
            <div
                className="relative"
                onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
            >
                {children}
            </div>
        </div>,
        document.querySelector('.modal-container')
    );
}

export default PromoModal;
