import Slider from "react-slick/lib/slider";
import soldBanner from "../images/SoldBannerRotated.png";

function UsedCard({images, title, text, sold}) {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        cssEase: "linear",
        swipe: true,
        arrows: true,
    };

    const slides = images.map((image, index) => (
        <div className="w-full h-60 flex justify-center items-center" key={index}>
        <img className="object-contain mx-auto my-0" src={image} alt={`Slide ${index}`} style={{ maxHeight: "100%", maxWidth: "100%" }}/>
        </div>
    ));

    return (
        <div className="flex flex-col gap-y-10 hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden relative">
            <div className="w-full h-100 relative">
                <Slider {...settings}>
                    {slides}
                </Slider>
                {sold && (
                        <div className="absolute inset-0 flex justify-center items-center pb-5">
                            <img src={soldBanner} alt="Sold" className="object-contain"/>
                        </div>
                    )}
            </div>
            <div>
            <h1 className="px-6 pb-4 font-bold justify-center items-center text-center">{title}</h1>
            </div>
            <div>
            <p style={{whiteSpace: "pre-line"}}>
                {text}
            </p>
            </div>
            <hr className="border border-slate-500 bg-slate-500 w-full" />
        </div>
    );

}

export default UsedCard;