import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function BlueGiantEquipmentPage() {
    const { productId } = useParams();
    const [iframeSrc, setIframeSrc] = useState('');
    const proxyUrl = 'https://www.hurricanelift.com:5000/proxy';
    const realProductId = productId.replace(/--/g, '/');
    const targetUrl = `https://www.bluegiant.com/Products/Industrial-Trucks/${realProductId}`;
    useEffect(() => {
        fetch(proxyUrl + '?url=' + encodeURIComponent(targetUrl))
            .then((response) => {
                return response.text();
            })
            .then((content) => {
                content = content.replace(
                    'if (typeof jQuery == \'undefined\') {document.write(\'<script type="text/javascript" src="https://www.bluegiant.com/CMSScripts/Custom/BlueGiant/jquery.min.js" href="https://www.bluegiant.com/"><\' + \'/script>\');}',
                    ''
                );
                content = content.replace(
                    '</head>',
                    '<script type="text/javascript" src="https://www.bluegiant.com/CMSScripts/Custom/BlueGiant/jquery.min.js"></script></head>'
                );

                const parser = new DOMParser();
                const doc = parser.parseFromString(content, 'text/html');

                console.log(doc)

                // Remove the elements you want
                const header = doc.querySelector('header');
                const breadcrumb = doc.querySelector('#Breadcrumb');
                const orangeBox = doc.querySelectorAll('.orangeBox');
                const footer = doc.querySelector('footer');
                const h3s = doc.querySelectorAll('h3');
                const iframes = doc.querySelectorAll('iframe');

                h3s.forEach(h3 => {
                    if (h3.textContent.toLowerCase().includes("video")) {  // remove the h3 element
                        h3.remove();
                    }
                });

                iframes.forEach(iframe => iframe.remove());

                if (header) header.remove();
                if (breadcrumb) breadcrumb.remove();
                if (orangeBox && orangeBox.length > 1) orangeBox[1].remove();
                if (footer) footer.remove();

                // Serialize the modified DOM object back to a string
                const serializer = new XMLSerializer();
                const modifiedContent = serializer.serializeToString(doc);

                const blob = new Blob([modifiedContent], { type: 'text/html' });
                const blobUrl = URL.createObjectURL(blob);
                setIframeSrc(blobUrl);
            })
            .catch((error) => {
                console.error(`Problem with fetch request: ${error.message}`);
                return (<div></div>);
            });
    }, []);

    return (
        <iframe
            title="fetched-content"
            src={iframeSrc}
            style={{ height: '100vh', width: '66%', border: 'none' }}
            className="mx-auto my-10 mt-30"
        ></iframe>
    );
}

export default BlueGiantEquipmentPage;