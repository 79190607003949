import classNames from 'classnames';
import { useState } from 'react';


function ImageZoom({ className, src, alt }) {
    const [zoomed, setZoomed] = useState(false);
  
    const final = classNames("overflow-hidden", className);
  
    return (
      <div
        className={final}
        onMouseEnter={() => setZoomed(true)}
        onMouseLeave={() => setZoomed(false)}
      >
        <img
          src={src}
          alt={alt}
          className={`transition-transform duration-500 ${
            zoomed ? 'transform scale-125' : 'transform scale-100'
          }`}
        />
      </div>
    );
  }

  export default ImageZoom;