import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function LindeEquipmentPage() {
    const { productId } = useParams();
    const [iframeSrc, setIframeSrc] = useState('');
    const proxyUrl = 'https://www.hurricanelift.com:5000/proxy';
    //const targetUrl = 'http://load-liftermfg.com/products/4400-series';
    const targetUrl = `https://www.kion-na.com/product_view/${productId}`;
    useEffect(() => {
        fetch(proxyUrl + '?url=' + encodeURIComponent(targetUrl))
        .then((response) => {
            return response.text();
        })
        .then((content) => {
            console.log(content);
            content = content.replace(/<img[^>]*?src\s*=\s*"[^"]*?www\.facebook\.com[^"]*?"[^>]*?\/?>/g, '');

            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            // Remove the elements you want
            const header = doc.querySelector('.header');
            const getAQuoteFooter = doc.querySelector('.get_a_quote_footer');
            const dealer = doc.querySelector('.product_header_left p');
            const footer = doc.querySelector('footer');
            const footerClass = doc.querySelector('.footer');
            const productTabs = doc.querySelector('.product_tabs');

            if (header) header.remove();
            if (getAQuoteFooter) getAQuoteFooter.remove();
            if (dealer) dealer.remove();
            if (footer) footer.remove();
            if (footerClass) footerClass.remove();
            if (productTabs) productTabs.remove();

            // Serialize the modified DOM object back to a string
            const serializer = new XMLSerializer();
            const modifiedContent = serializer.serializeToString(doc);

            const blob = new Blob([modifiedContent], { type: 'text/html' });
            const blobUrl = URL.createObjectURL(blob);
            setIframeSrc(blobUrl);
        })
        .catch((error) => {
            console.error(`Problem with fetch request: ${error.message}`);
            return (<div></div>);
        });
    }, []);

    return (
        <iframe
            title="fetched-content"
            src={iframeSrc}
            style={{ height: '100vh', width: '66%', border: 'none' }}
            className="mx-auto my-10 mt-30"
        ></iframe>
  );
}

export default LindeEquipmentPage;