import { useEffect, useState, useRef } from "react";
import Splash from "../components/Splash";
import bgImg from "../images/extra-pic.jpg"
import liftLogo from "../images/logo-lift-white.png"
import liftTruckImg from "../images/used-forklifts-e1640186698643.jpeg"
import usedCertified from "../images/usedCertified.png"

function StaffDirectoryPage() {
    const [staffData, setStaffData] = useState([]);
    const tableRef = useRef([]);

    const text = (
        <div>
            <img src={liftLogo} className="w-1/3 md:w-2/3" />
            <h1 className="text-4xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold pb-2 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Hurricane<br />Lift Truck</h1>
            <p className="text-3xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold pb-5 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Our Team</p>
        </div>
    );

    useEffect(() => {
        (async () => {
            const staff = await fetch('https://www.hurricanelift.com:5000/staff-directory')
                .then(res => res.json())
                .then((data) => {
                    console.log(data.rows);
                    setStaffData(data.rows);
                });
        })();
    }, []);

    // Group staff data by province
    const staffByProvince = staffData.reduce((acc, person) => {
        const province = person.province;
        if (acc[province]) {
            acc[province].push(person);
        } else {
            acc[province] = [person];
        }
        return acc;
    }, {});

    // Column width calculation and adjustment
    useEffect(() => {
        let colWidths = [];
      
        // Loop over each table
        for (let table of tableRef.current) {
          if (table && table.rows[0]) {
            // Loop over each cell in the header row
            let cells = table.rows[0].cells;
            for (let i = 0; i < cells.length; i++) {
              let currentMaxWidth = colWidths[i] || 0;
              let currentCellWidth = cells[i].offsetWidth;
      
              if (currentCellWidth > currentMaxWidth) {
                colWidths[i] = currentCellWidth;
              }
            }
          }
        }
      
        // Set the column widths
        for (let table of tableRef.current) {
          if (table) {
            let tableWidth = table.offsetWidth;
            let numColumns = table.rows[0].cells.length;
            let rows = Array.from(table.rows);
            for (let row of rows) {
              let cells = row.cells;
              for (let i = 0; i < cells.length; i++) {
                let percentageWidth = (colWidths[i] / tableWidth) * 100;
                //cells[i].style.width = `${percentageWidth}%`;
                cells[i].style.width = `${colWidths[i]}px`;
              }
            }
          }
        }
      }, [staffData]); // Re-run this whenever the staff data changes
      

    return (
        <div>
            <Splash image={bgImg} text={text} />
            {Object.entries(staffByProvince).map(([province, staff], index) => (
                <div className="flex flex-col items-center mx-auto" key={province}>
                    <div className="items-start">
                        <h2 className="text-2xl uppercase font-bold text-left mt-6">
                            {province === 'NS'
                                ? 'Nova Scotia'
                                : province === 'NL'
                                    ? 'Newfoundland'
                                    : 'New Brunswick'}
                        </h2>
                        <div className="flex flex-col justify-center md:hidden">  {/* Show on mobile */}
                            {staff.map((person) => (
                                <div className="bg-white shadow-md rounded-md p-4 m-2 w-full" key={person.name}>
                                    <h3 className="font-bold text-xl mb-2">{person.name}</h3>
                                    <p><strong>Position:</strong> {person.position}</p>
                                    <p><strong>Email:</strong> {person.email}</p>
                                    <p><strong>Phone:</strong> {person.phone}</p>
                                </div>
                            ))}
                        </div>
                        <div className="hidden md:flex justify-center">
                            <div className="overflow-x-auto">
                                <table className="w-full table-auto mt-4" ref={(el) => (tableRef.current[index] = el)}>
                                    <colgroup>
                                        <col className="w-auto" />
                                        <col className="w-auto" />
                                        <col className="w-auto" />
                                        <col className="w-auto" />
                                    </colgroup>
                                    <thead className="bg-hurricane-orange text-white">
                                        <tr>
                                            <th className="md:px-4 md:py-2 md:whitespace-nowrap">Name</th>
                                            <th className="md:px-4 md:py-2 md:whitespace-nowrap">Position</th>
                                            <th className="md:px-4 md:py-2 md:whitespace-nowrap">Email</th>
                                            <th className="md:px-4 md:py-2 md:whitespace-nowrap">Phone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staff.map((person) => (
                                            <tr className="border-b border-gray-200" key={person.id}>
                                                <td className="md:px-4 md:py-2 md:whitespace-nowrap">{person.name}</td>
                                                <td className="md:px-4 md:py-2 md:whitespace-nowrap">{person.position}</td>
                                                <td className="md:px-4 md:py-2 md:whitespace-nowrap">{person.email}</td>
                                                <td className="md:px-4 md:py-2 md:whitespace-nowrap">{person.phone}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ))}



        </div>
    );
}

export default StaffDirectoryPage;