import { configureStore } from '@reduxjs/toolkit';
import { addPart, removePart, changeSearchTerm, partsReducer } from './slices/PartsSlice';


const store = configureStore({
    reducer: {
        parts: partsReducer
    }
});

export { store, addPart, removePart, changeSearchTerm };