import { useState } from 'react';

function NewArrival({ image, name, link }) {

    const [showButton, setShowButton] = useState(false);


    const handleMouseEnter = () => {
        setShowButton(true);
    }

    const handleMouseExit = () => {
        setShowButton(false);
    }

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
            <div className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                <a href={link} alt="" className="flex flex-col items-center justify-center font-bold uppercase p-5 gap-10 w-full h-full bg-white">
                    <img className="w-full my-3 border border-dark b-1 rounded-lg" src={image} alt="" />
                    {showButton && (<span className="items-center justify-center bg-orange-600 text-white font-bold border-r rounded-full px-2 text-lg">Shop Now</span>)}
                    <p className="px-6 py-4 font-bold">{name}</p>
                    <hr className="border border-slate-500 bg-slate-500 w-full" />
                </a>
            </div>
        </div>
    );
}

export default NewArrival;