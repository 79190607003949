import { GoChevronDown, GoChevronLeft } from 'react-icons/go';
import Panel from './Panel';
import { useEffect, useRef } from 'react';

function InfoDropdown({ title, description, isOpen, onClick }) {
    const contentRef = useRef(null);
    const classNames = isOpen ? "flex items-center justify-between font-bold uppercase cursor-pointer bg-white" : "flex items-center justify-between font-bold uppercase cursor-pointer";

    let contentHeight = isOpen ? `${contentRef.current?.scrollHeight}px` : "0";

    useEffect(() => {
        contentRef.current.style.height = isOpen ? `${contentRef.current.scrollHeight}px` : "0";
    }, [isOpen]);

    return (
        <div className="relative bg-neutral-300 rounded border border-black">
            <Panel className={classNames} onClick={onClick}>
                {title}
                {(isOpen && <GoChevronDown className="text-lg" />) || <GoChevronLeft className="text-lg" />}
            </Panel>
            <div ref={contentRef} style={{ height: contentHeight }} className={`transition-all duration-300 ease-in-out overflow-hidden`}>
                <Panel className={`bg-white`}>
                    <div className="p-1">
                        {description}
                    </div>
                </Panel>
            </div>
        </div>
    );
}

export default InfoDropdown;