import { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/Top-Image-hyundai-e1638150566478.jpg";
import Tab from "../components/Tab";
import { Link } from "react-router-dom";

function HyundaiConstructionPage() {
    const [activeTab, setActiveTab] = useState("Compact Excavators"); // Default active tab
    const [equipmentData, setEquipmentData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('sending out request for construction catalog');
                const response = await fetch('https://www.hurricanelift.com:5000/constructionequipment?vendor=Hyundai');

                if (!response.ok) {
                    console.log('Error fetching data');
                } else {
                    const data = await response.json();
                    console.log(data);
                    
                    const equipmentByType = {
                        'Compact Excavators': data.equipment.filter((equipment) => equipment.type === 'Compact Excavator'),
                        'Wheel Loaders': data.equipment.filter((equipment) => equipment.type === 'Compact Wheel Loader'),
                        'Track Loaders': data.equipment.filter((equipment) => equipment.type === 'Compact Track Loader'),
                    };

                    setEquipmentData(equipmentByType);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const text = <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold">Hyundai Construction Equipment</h1>;

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className='mx-auto flex items-center justify-center'>
                {/* Render a Tab component for each equipment type */}
                {Object.keys(equipmentData).map((type) => (
                    <Tab key={type} name={type} activeTab={activeTab} setActiveTab={setActiveTab} />
                ))}
            </div>
            <div>
                {equipmentData[activeTab] &&
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4 w-full md:w-1/2 mx-auto my-10">
                        {equipmentData[activeTab].map((equipment) => (
                            <div key={equipment.id} className="hover:drop-shadow-[0_10px_10px_rgba(0,0,0,.5)] rounded-xl overflow-hidden">
                                <Link to={`/hyundaiconstruction/${equipment.link.replace(/\//g, '--')}`} className="flex flex-col items-center justify-center font-bold uppercase border border-gray-400 border-b-2 border-x-0 border-t-0 p-5 gap-10 w-full h-full bg-white">
                                    <img src={`https://www.hurricanelift.com:5000${equipment.image}`}//src={`data:image/jpeg;base64,${equipment.image}`}
                                        alt={equipment.name} className="w-full h-full shrink border-red-500 border rounded-lg object-scale-down" />
                                    {equipment.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

export default HyundaiConstructionPage;
