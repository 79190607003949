import React from 'react';
import NavMenu from './NavMenu';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

function Navbar() {

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div className="fixed top-0 left-0 w-full z-50">
        <div className="bg-black">
          <div className="container mx-auto py-2">
            <h1 className="text-white font-bold text-center text-5xl">CALL TOLL FREE <span className="whitespace-nowrap">1-800-463-7100</span></h1>
          </div>
        </div>
        <div>
          <nav className="navbar flex-col navbar-expand-lg">
            <div className="container-fluid" >
              <NavMenu />
            </div>
          </nav>
        </div>
      </div>
      <div className="flex-grow" style={{marginTop: '7rem'}}>
        <Outlet/>
      </div>
      <Footer />
    </div>
  );
}

export default Navbar;