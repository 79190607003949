import React, { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/extra-pic.jpg"
import liftLogo from "../images/logo-lift-white.png"
import liftTruckImg from "../images/used-forklifts-e1640186698643.jpeg"
import usedCertified from "../images/usedCertified.png"
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "../components/button";
import Modal from "../components/Modal";
import UsedCards from '../components/UsedCards';

function UsedPage() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        captcha: '',
    });
    const [error, setError] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {

        const newDiv = document.createElement("div");
        newDiv.className = "modal-container";

        document.body.appendChild(newDiv);

        return () => {
            newDiv.remove();
        }

    }, []);

    const text = (
        <div>
            <img src={liftLogo} className="w-2/3" />
            <h1 className="text-3xl xl:text-4xl 2xl:text-6xl uppercase text-white font-bold pb-2">Used<br />Products</h1>
            <p className="text-2xl md:text-3xl text-white pb-5">We've got you covered.</p>
        </div>
    );

    const errorText = (<span className="text-red-500"> * Please fill this in</span>);

    const actionBar = <div><Button primary onClick={handleClose}>Okay</Button></div>

    const modal = <Modal onClose={handleClose} actionBar={actionBar}>
        <p>{modalMessage}</p>
    </Modal>;

    const handleSubmit = async (event) => {
        event.preventDefault();
        //Todo:
        //Handle submitting form and return success message, maybe a modal
        const { name, email, message, captcha } = formData;
        const errorList = [];

        if (!name) errorList.push("name");
        if (!email || !email.includes('@')) errorList.push("email");
        if (!message) errorList.push("message");
        if (!captcha) errorList.push("captcha");

        console.log(errorList);
        setError(errorList);

        if (errorList.length > 0) return;

        console.log('formData', JSON.stringify(formData));
        try {
            const response = await fetch('https://www.hurricanelift.com:5000/api/send-preowned-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.success) {
                console.log("successful");
                setModalMessage("Success! We will be in contact with you as soon as possible.");
                setShowModal(true);
            } else {
                // Do something when the email fails to send
                //data.issue will be used to capture what field exactly caused the problem (eg they didn't fill out their name, or their email, or the captcha)
                //Should highlight the problem field in red so the customer can fix it
                //Need to handle issues:
                //name, email, message, captcha
                if (data.issue === 'name') {
                    errorList.push("name");
                }
                if (data.issue === 'email') {
                    errorList.push("email");
                }
                if (data.issue === 'message') {
                    errorList.push("message");
                }
                if (data.issue === 'captcha') {
                    errorList.push("captcha");
                }
                console.log(data.message);
                setModalMessage(`There was an error attempting to submit the form:\n${data.message}`);
                setShowModal(true);
                setError(errorList);
            }
        } catch (error) {
            // Handle error
            console.error('Error:', error);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (error.includes(name)) {
            setError(error.filter((e) => e !== name));
        }

        if (name === 'file') {
            setFormData({ ...formData, [name]: event.target.files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setFormData({ ...formData, captcha: value });
    }

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className="flex flex-col lg:flex-row my-10 items-center justify-center mx-auto" style={{ maxWidth: '80%' }}>
                <div>
                    <img src={liftTruckImg} />
                    <div>
                        <p>
                            All makes and models of used forklifts and material handling equipment can be certified by Hurricane Industrial. Our certified used equipment undergoes a rigorous rebuild procedure including all body components sanded and repainted. Then, all major components from power train, frame, mast, electrical systems, hydraulic, and under carriage are repaired, rebuilt or replaced.
                        </p>
                        <br />
                        <p>
                            The extensive rebuild process covers a 200 point inspection. You can rest assured that all Hurricane used/Certified equipment has been thoroughly inspected, expertly repaired and is guaranteed. We stand by our Certified forklifts offering a limited warranty of four months or 500 hours whichever comes first. (Refer to Warranty Certificate for inclusions and exclusions).
                        </p>
                        <br />
                        <p>
                            For units not undergoing our certified process, we sell “as is – where is” with no implied warranty other than 30 day satisfaction guarantee. Please refer to specific warranty offered by unit.
                        </p>
                    </div>
                    <div>
                        <UsedCards />
                    </div>
                    <div>
                        <img src={usedCertified} />
                        <div>
                            <h3 className="font-bold uppercase text-3xl md:text-5xl">Interested in more information on our used equipment?</h3>
                            <p>Email Matt Baird at <a href="mbaird@hurricanelift.com" className="text-blue-500 underline">mbaird@hurricanelift.com</a> today using the form below, or call us at 902-468-8518</p>
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col md:flex-row">
                                    <div className="flex flex-col w-full justify-left items-left">
                                        <label htmlFor="name" className="form-label">Name {error.includes("name") && errorText}</label>
                                        <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 mr-3" value={formData.name} onChange={handleChange} id="name" name="name" />
                                    </div>
                                    <div className="flex flex-col w-full justify-left items-left">
                                        <label htmlFor="email" className="form-label">Email {error.includes("email") && errorText}</label>
                                        <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 mr-3" value={formData.email} onChange={handleChange} id="email" name="email" />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row">
                                    <div className="flex flex-col w-full">
                                        <label htmlFor="message" className="form-label">Message {error.includes("message") && errorText}</label>
                                        <textarea type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 w-full" value={formData.message} onChange={handleChange} id="message" name="message" rows="10"></textarea>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row">
                                    <div>
                                        <div>
                                            {error.includes("captcha") && errorText}
                                            <ReCAPTCHA
                                                sitekey="6LfXQ1YjAAAAABJM8C9YCa0phFfcd8QL_SAZFnb1"
                                                onChange={onCaptchaChange}
                                            />
                                        </div>
                                        <br />
                                        <Button className="bg-gray-100 px-5" secondary outline>Submit</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && modal}
        </div>
    );

}

export default UsedPage;