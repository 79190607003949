import Button from './button';

// Tab Component
function Tab({ name, children, activeTab, setActiveTab }) {
    const isActive = activeTab === name;

    return (
        <div className="my-4">
            <Button
                onClick={() => setActiveTab(name)}
                className={`px-4 py-2 text-lg font-bold uppercase italic rounded ${isActive ? 'text-hurricane-orange border-y-0' : 'text-black border-0'}`}
            >
                {name}
            </Button>
            {isActive && <div className="mt-4">{children}</div>}
        </div>
    );
}

export default Tab;
