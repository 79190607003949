function Splash({ image, text }) {
    // return (
    //     <div className="overflow-hidden">
    //         <div className="flex items-start w-screen backdrop-brightness-50" style={{background: `80% url(${image}) no-repeat`, backgroundSize: 'cover',  height: '45vh'}}>
    //             <div className="z-10 ml-5 pl-[10%] pt-10 sm:pt-16 md:pt-24 lg:pt-32 xl:pt-40 2xl:pt-48">
    //                 {text}
    //             </div>
    //             <div className="fixed inset-0 bg-black opacity-20 overflow-hidden"></div>
    //         </div>
    //     </div>
    // );
    return (
        <div className="overflow-hidden">
            <div className="relative flex items-center justify-center w-screen h-45vh backdrop-brightness-50" style={{background: `url(${image}) center center / cover no-repeat`, minHeight: '300px'}}>
                <div className="z-10 text-center p-5" style={{ maxWidth: '90%', maxHeight: '100%' }}>
                    {text}
                </div>
                <div className="absolute inset-0 bg-black opacity-20"></div>
            </div>
        </div>
    );
}

export default Splash;