import Slider from 'react-slick';
import hyundai from '../images/Top-Image-hyundai-e1638150566478.jpg';
import linde from '../images/Top-Image-Linde-e1638150520682.jpg';
import loadlifter from '../images/LoadLifter-Banner.jpg';
import hyundaiconstruction from '../images/HX-compact-group-G21560_HCE-slide.jpg';

function SplashCarousel() {

    const images = [
        hyundai,
        linde,
        loadlifter,
        hyundaiconstruction
    ];

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        swipe: false,
        arrows: false,
      };

    const slides = images.map((image, index) => (
        <div className="uppercase text-white w-full" key={index}>
            <div 
                className="flex items-center justify-start w-full bg-center bg-no-repeat bg-cover relative h-[32rem]" 
                style={{backgroundImage: `url(${image})`}}
            >
                <div className="z-10 px-10 sm:px-20">
                    <h3 className="font-bold mb-2 text-3xl sm:text-4xl md:text-6xl">
                        {index === 0 ? "Hyundai Forklifts" : index === 1 ? "Linde Forklifts" : index === 2 ? "Loadlifter" : "Hyundai Construction Equipment"}
                    </h3>
                    <p className="text-2xl sm:text-3xl md:text-4xl">
                        {index === 0 ? "Get real" : index === 1 ? "Trusted Worldwide" : index === 2 ? "Quality Driven" : ""}
                    </p>
                    {index !== 1 && index !== 3 && (
                        <p className="font-bold text-2xl sm:text-3xl md:text-4xl">
                            {index === 0 ? "Get Hyundai" : "Quality Delivered"}
                        </p>
                    )}
                </div>
                <div className="absolute inset-0 bg-black opacity-10"></div>
            </div>
        </div>
    ));

    return (
        <div className=" w-screen">
            <Slider {...settings}>
                {slides}
            </Slider>
        </div>
    );
}

export default SplashCarousel;