import { createSlice, nanoid } from "@reduxjs/toolkit";

const partsSlice = createSlice({
    name: "parts",
    initialState: {
        searchTerm: "",
        data: []
    },
    reducers: {
        changeSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        addPart(state, action) {
            //assumption is that action.payload === { name, cost }
            state.data.push({id: nanoid(), brand: action.payload.brand, name: action.payload.name, cost: action.payload.cost, item: action.payload.item, description: action.payload.description});
        },
        removePart(state, action) {
            state.data = state.data.filter((car) => car.id !== action.payload);
        }
    }
});

export const { addPart, removePart, changeSearchTerm } = partsSlice.actions;
export const partsReducer = partsSlice.reducer;