import { useState, useEffect } from "react";
import InfoDropdown from "../components/InfoDropdown";
import Splash from "../components/Splash";
import liftTruckImg from "../images/2-rentals.jpg";
import bgImg from "../images/extra-pic.jpg";
import liftLogo from "../images/logo-lift-white.png";
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "../components/button";
import Modal from "../components/Modal";

function RentalsPage() {

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        contactMethod: 'Email',
        email: '',
        phone: '',
        subject: '',
        message: '',
        captcha: '',
    });
    const [error, setError] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {

        const newDiv = document.createElement("div");
        newDiv.className = "modal-container";

        document.body.appendChild(newDiv);

        return () => {
            newDiv.remove();
        }

    }, []);

    const errorText = <span className="text-red-500"> * Please fill this in</span>

    const text = <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold pb-10 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Contact Us</h1>

    const actionBar = <div><Button primary onClick={handleClose}>Okay</Button></div>

    const modal = <Modal onClose={handleClose} actionBar={actionBar}>
        <p>{modalMessage}</p>
    </Modal>;

    const handleSubmit = async (event) => {
        event.preventDefault();
        //Todo:
        //Handle submitting form and return success message, maybe a modal
        const { name, contactMethod, email, phone, subject, message, captcha } = formData;
        const errorList = [];

        if (!name) errorList.push("name");
        if (!contactMethod) errorList.push("contactMethod");
        if (!email || !email.includes('@')) errorList.push("email");
        if (!phone) errorList.push("phone");
        if (!subject) errorList.push("subject");
        if (!message) errorList.push("message");
        if (!captcha) errorList.push("captcha");

        console.log(errorList);
        setError(errorList);

        //if (errorList.length > 0) return;

        console.log('formData', JSON.stringify(formData));
        try {
            const response = await fetch('https://www.hurricanelift.com:5000/api/send-rental-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.success) {
                console.log("successful");
                setModalMessage("Success! We will be in contact with you as soon as possible.");
                setShowModal(true);
                // Do something after the email is successfully sent
            } else {
                // Do something when the email fails to send
                //data.issue will be used to capture what field exactly caused the problem (eg they didn't fill out their name, or their email, or the captcha)
                //Should highlight the problem field in red so the customer can fix it
                //Need to handle issues:
                //name, email, phone, subject, message, captcha
                if (data.issue === 'name') {
                    errorList.push("name");
                }
                if (data.issue === 'contactMethod') {
                    errorList.push("contactMethod");
                }
                if (data.issue === 'email') {
                    errorList.push("email");
                }
                if (data.issue === 'phone') {
                    errorList.push("phone");
                }
                if (data.issue === 'subject') {
                    errorList.push("subject");
                }
                if (data.issue === 'message') {
                    errorList.push("message");
                }
                if (data.issue === 'captcha') {
                    errorList.push("captcha");
                }
                console.log(data.message);
                setModalMessage(`There was an error attempting to submit the form:\n${data.message}`);
                setShowModal(true);
                setError(errorList);
            }
        } catch (error) {
            // Handle error
            console.error('Error:', error);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (error.includes(name)) {
            setError(error.filter((e) => e !== name));
        }
        setFormData({ ...formData, [name]: value });
    }

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setFormData({ ...formData, captcha: value });
    }

    const bgText = (
        <div >
            <img src={liftLogo} className="w-1/3 md:w-2/3" />
            <h1 className="text-5xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold pb-10 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Hurricane<br />Lift Truck</h1>
        </div>
    );

    const descriptions = {
        one: "Daily, weekly, monthly, or long-term rates; We'll accomodate all your needs.",
        two: "One monthly invoice that includes rental and maintenance. No equipment worries for you, so you can focus on your business.",
        three: "Our fleet has an average of only 1400 hours per lift truck! Rent-safe, reliable, frequently serviced lift trucks protect your staff.",
        four: "Electric, diesel, LPG, pneumatic or cushion tire, sit-down or narrow aisle, electric pallet jacks. We have them all! We range from 3,000lb to 25,000lb.",
        five: "We will take care of all pickups and deliveries.",
        six: "We have competitive pricing, including rent to own prices. We offer discount rates to all Planned Maintenance customers.",
    };

    const titles = {
        one: "Flexible Daily Terms",
        two: "Maintenance Included",
        three: "Late Model Forklifts",
        four: "Wide Range of Configurations",
        five: "Same Day / Next Day Delivery To Your Door",
        six: "Competitive Pricing",
    };

    const handleClick = (key) => {
        setActiveDropdown(activeDropdown === key ? null : key);
    }

    return (
        <div>
            <div>
                <Splash image={bgImg} text={bgText} />
            </div>
            <div className="flex flex-col md:flex-row columns-2 mx-auto my-10 gap-5 items-start justify-center w-4/5 md:w-1/2">
                <div className="flex flex-col columns-2 gap-5 items-start justify-center">
                    <img className="w-full" src={liftTruckImg} />
                    <div className="w-full">
                        <div className="gap-5">
                            <ul className="flex flex-col list-disc items-center">
                            {Object.keys(titles).map(key => (
                                // <InfoDropdown
                                //     key={key}
                                //     title={titles[key]}
                                //     description={descriptions[key]}
                                //     isOpen={activeDropdown === key}
                                //     onClick={() => handleClick(key)}
                                // />
                                <li
                                    key={key}
                                    className="p-2 relative text-center items-center justify-center font-bold uppercase"
                                >
                                    {titles[key]}
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mx-auto">
                    <h3 className="font-bold text-3xl">Are you looking for a forklift rental? Contact us today!</h3>
                    <p className="my-2">
                        Please fill out the form, and someone from Hurricane will get back to you as soon as possible.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="name" className="form-label">Name {error.includes("name") && errorText}</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.name} onChange={handleChange} id="name" name="name" />
                            </div>
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="contactMethod" className="md:ml-3 form-label">Preferred Contact Method {error.includes("contactMethod") && errorText}</label>
                                <select value={formData.contactMethod} onChange={handleChange} id="contactMethod" name="contactMethod" className="p-1 my-3 bg-gray-50 border border-gray-300 md:ml-3" >
                                    <option value="email">Email</option>
                                    <option value="phone">Phone</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="email" className="form-label">Email {error.includes("email") && errorText}</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.email} onChange={handleChange} id="email" name="email" />
                            </div>
                            <div className="flex flex-col w-full">
                                <label htmlFor="phone" className="md:ml-3 form-label">Phone Number {error.includes("phone") && errorText}</label>
                                <input type="tel" className="p-1 my-3 bg-gray-50 border border-gray-300 md:ml-3" value={formData.phone} onChange={handleChange} id="phone" name="phone" pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}" placeholder="555-555-5555" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full">
                                <label htmlFor="subject" className="form-label">Subject {error.includes("subject") && errorText}</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300" value={formData.subject} onChange={handleChange} id="subject" name="subject" />
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-col w-full">
                                <label htmlFor="message" className="form-label">Message {error.includes("message") && errorText}</label>
                                <textarea type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 w-full" value={formData.message} onChange={handleChange} id="message" name="message" rows="10"></textarea>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div>
                                <div>
                                    {error.includes("captcha") && errorText}
                                    <ReCAPTCHA
                                        sitekey="6LfXQ1YjAAAAABJM8C9YCa0phFfcd8QL_SAZFnb1"
                                        onChange={onCaptchaChange}
                                    />
                                </div>
                                <br />
                                <Button className="bg-gray-100 px-5" secondary outline>Submit</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {showModal && modal}
        </div>
    );
}

export default RentalsPage;