import React, { useState, useEffect } from 'react';
import InfoDropdown from "../components/InfoDropdown";
import Splash from "../components/Splash";
import liftTruckImg from "../images/Parts-page.jpg"
import liftLogo from "../images/logo-lift-white.png"
import bgImg from "../images/extra-pic.jpg"
import contactImage from "../images/IMG_2928-2-scaled.jpg";
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "../components/button";
import Modal from "../components/Modal";

function PartsPage() {

    const [activeDropdown, setActiveDropdown] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        email: '',
        phone: '',
        make: '',
        serialNumber: '',
        partsRequired: '',
        message: '',
        file: null,
        captcha: '',
    });
    const [error, setError] = useState([]);
    
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {

        const newDiv = document.createElement("div");
        newDiv.className = "modal-container";

        document.body.appendChild(newDiv);

        return () => {
            newDiv.remove();
        }

    },[]);

    const text = (
        <div>
            <img src={liftLogo} className="w-1/3 md:w-2/3" />
            <h1 className="text-3xl xl:text-5xl uppercase text-white font-bold pb-2 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Hurricane<br />Lift Truck</h1>
            <p className="text-2xl xl:text-3xl text-white pb-5 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">We have the parts you need!</p>
            <p className="text-1xl text-white uppercase font-bold items-start bg-hurricane-orange pl-2 p-1">22,000 Part Inventory</p>
        </div>
    );

    const errorText = <span className="text-red-500"> * Required</span>

    const actionBar = <div><Button primary onClick={handleClose}>Okay</Button></div>

    const modal = <Modal onClose={handleClose} actionBar={actionBar}>
        <p>{modalMessage}</p>
    </Modal>;

    const handleClick = (key) => {
        setActiveDropdown(activeDropdown === key ? null : key);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        //Todo:
        //Handle submitting form and return success message, maybe a modal
        const { name, companyName, email, phone, make, serialNumber, partsRequired, message, file, captcha } = formData;
        const errorList = [];

        if (!name) errorList.push("name");
        if (email && !email.includes('@')) errorList.push("email");
        if (!phone) errorList.push("phone");
        if (!make) errorList.push("make");
        if (!serialNumber) errorList.push("serialNumber");
        if (!partsRequired) errorList.push("partsRequired");
        if (!captcha) errorList.push("captcha");

        console.log(errorList);
        setError(errorList);

        if (errorList.length > 0) return;

        const data = new FormData();

        // Adding all form fields to the FormData object
        data.append('name', name);
        data.append('companyName', companyName);
        data.append('email', email);
        data.append('phone', phone);
        data.append('make', make);
        data.append('serialNumber', serialNumber);
        data.append('partsRequired', partsRequired);
        data.append('message', message);
        data.append('captcha', captcha);

        // Add file if it exists
        if (file) {
            console.log("appending file")
            data.append('file', file);
        }

        try {
            const response = await fetch('https://www.hurricanelift.com:5000/api/send-parts-email', {
                method: 'POST',
                body: data,
            });

            const errorList = [];
            const responseData = await response.json();
            if (responseData.success) {
                console.log("successful");
                setModalMessage("Success! We will be in contact with you as soon as possible.");
                setShowModal(true);
            } else {
                // Do something when the email fails to send
                //data.issue will be used to capture what field exactly caused the problem (eg they didn't fill out their name, or their email, or the captcha)
                //Should highlight the problem field in red so the customer can fix it
                //Need to handle issues:
                //name, email, phone, partsRequired, message, captcha
                if (responseData.issue === 'name') {
                    errorList.push("name");
                }
                if (responseData.issue === 'companyName') {
                    errorList.push("companyName");
                }
                if (responseData.issue === 'email') {
                    errorList.push("email");
                }
                if (responseData.issue === 'phone') {
                    errorList.push("phone");
                }
                if (responseData.issue === 'make') {
                    errorList.push("make");
                }
                if (responseData.issue === 'serialNumber') {
                    errorList.push("serialNumber");
                }
                if (responseData.issue === 'partsRequired') {
                    errorList.push("partsRequired");
                }
                if (responseData.issue === 'message') {
                    errorList.push("message");
                }
                if (responseData.issue === 'file') {
                    errorList.push("file");
                }
                if (responseData.issue === 'captcha') {
                    errorList.push("captcha");
                }
                setModalMessage(`There was an error attempting to submit the form:\n${responseData.message}`);
                setShowModal(true);
                console.log(responseData.message);
                setError(errorList);
            }
        } catch (error) {
            // Handle error
            console.error('Error:', error);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (error.includes(name)) {
            setError(error.filter((e) => e !== name));
        }

        if (name === 'file') {
            setFormData({ ...formData, [name]: event.target.files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setFormData({ ...formData, captcha: value });
    }

    const descriptions = {
        one: "Our fleet of mobile repair vans covers all of Atlantic Canada. We know where our technicians are, so we can get to you and your material handling equipment faster.",
        two: "We are committed to perform the highest level of service and repair on your forklift or other material handling equipment. We are so confident in our ability we’ve created a Performance Guarantee for our customers.",
        three: "For fleet users we can lower your operating costs, track your service costs, and provide monthly or quarterly reporting, including per-hour cost per truck. In addition, never have a forklift or other lift truck down with our loaner program with guaranteed response time and no-hassle maintenance program."
    };

    const titles = {
        one: "GPS Dispatched Service",
        two: "Performance Guarantee",
        three: "Fleet Maintenance Program"
    };


    return (
        <div>
            <div>
                <Splash image={bgImg} text={text} />
            </div>
            <div className="flex flex-col columns-2 lg:flex-row my-10 mx-auto items-start gap-5" style={{ maxWidth: '80%' }}>
                <div className="flex flex-col columns-1 gap-5 justify-center items-start md:w-1/2 mt-5">
                    <div>
                        <div className="flex flex-col gap-5">
                            {Object.keys(titles).map(key => (
                                <InfoDropdown
                                    key={key}
                                    title={titles[key]}
                                    description={descriptions[key]}
                                    isOpen={activeDropdown === key}
                                    onClick={() => handleClick(key)}
                                />
                            ))}
                        </div>
                    </div>
                    <img src={liftTruckImg} className="drop-shadow-lg" />
                </div>
                <div className="w-full md:w-1/2">
                    <h3 className="font-bold uppercase text-3xl md:text-5xl">Parts and Accessories Request</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="name" className="form-label">Name {errorText}</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.name} onChange={handleChange} id="name" name="name" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="companyName" className="form-label">Company Name (Optional)</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.companyName} onChange={handleChange} id="companyName" name="companyName" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="email" className="form-label">Email (Optional)</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.email} onChange={handleChange} id="email" name="email" />
                            </div>
                            <div className="flex flex-col w-full">
                                <label htmlFor="phone" className="md:ml-3 form-label">Phone Number {errorText}</label>
                                <input type="tel" className="p-1 my-3 bg-gray-50 border border-gray-300 md:ml-3" value={formData.phone} onChange={handleChange} id="phone" name="phone" pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}" placeholder="555-555-5555" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="make" className="form-label">Make & Model {errorText}</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.make} onChange={handleChange} id="make" name="make" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full justify-left items-left">
                                <label htmlFor="serialNumber" className="form-label">Serial Number {errorText}</label>
                                <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300" value={formData.serialNumber} onChange={handleChange} id="serialNumber" name="serialNumber" />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full">
                                <label htmlFor="partsRequired" className="form-label">Parts Required {errorText}</label>
                                <textarea type="text" className="p-1 my-3 bg-gray-50 border border-gray-300" value={formData.partsRequired} onChange={handleChange} id="partsRequired" name="partsRequired" rows="5"></textarea>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full">
                                <label htmlFor="message" className="form-label">Message (Optional)</label>
                                <textarea type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 w-full" value={formData.message} onChange={handleChange} id="message" name="message" rows="5"></textarea>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="flex flex-col w-full">
                                <label htmlFor="file" className="form-label">Picture of the Capacity Plate (Optional)</label>
                                <input type="file" accept="image/*" className="p-1 my-3 bg-gray-50 border border-gray-300 w-full" onChange={handleChange} id="file" name="file"></input>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div>
                                <div>
                                    {error.includes("captcha") && errorText}
                                    <ReCAPTCHA
                                        sitekey="6LfXQ1YjAAAAABJM8C9YCa0phFfcd8QL_SAZFnb1"
                                        onChange={onCaptchaChange}
                                    />
                                </div>
                                <br />
                                <Button className="bg-gray-100 px-5" secondary outline>Submit</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {showModal && modal}
        </div>
    );
}

export default PartsPage;