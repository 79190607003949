import React, { useState, useEffect } from 'react';
import Splash from "../components/Splash";
import bgImg from "../images/extra-pic.jpg"
import contactImage from "../images/IMG_2928-2-scaled.jpg";
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "../components/button";
import Modal from "../components/Modal";

function ContactPage() {

    const [formData, setFormData] = useState({
        name: '',
        contactMethod: 'Email',
        email: '',
        phone: '',
        subject: '',
        message: '',
        captcha: '',
    });
    const [error, setError] = useState([]);
    
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleClose = () => {
        setShowModal(false);
    }

    // useEffect(() => {

    //     const newDiv = document.createElement("div");
    //     newDiv.className = "modal-container";

    //     document.body.appendChild(newDiv);

    //     return () => {
    //         newDiv.remove();
    //     }

    // },[]);

    const errorText = <span className="text-red-500"> * Please fill this in</span>

    const text = <h1 className="text-6xl xl:text-7xl 2xl:text-9xl uppercase text-white font-bold pb-10 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] md:drop-shadow-none">Contact Us</h1>

    const actionBar = <div><Button primary onClick={handleClose}>Okay</Button></div>

    const modal = <Modal onClose={handleClose} actionBar={actionBar}>
        <p>{modalMessage}</p>
    </Modal>;

    const handleSubmit = async (event) => {
        event.preventDefault();
        //Todo:
        //Handle submitting form and return success message, maybe a modal
        const { name, contactMethod, email, phone, subject, message, captcha } = formData;
        const errorList = [];

        if (!name) errorList.push("name");
        if (!contactMethod) errorList.push("contactMethod");
        if (!email || !email.includes('@')) errorList.push("email");
        if (!phone) errorList.push("phone");
        if (!subject) errorList.push("subject");
        if (!message) errorList.push("message");
        if (!captcha) errorList.push("captcha");

        console.log(errorList);
        setError(errorList);

        //if (errorList.length > 0) return;

        console.log('formData', JSON.stringify(formData));
        try {
            const response = await fetch('https://www.hurricanelift.com:5000/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.success) {
                console.log("successful");
                setModalMessage("Success! We will be in contact with you as soon as possible.");
                setShowModal(true);
                // Do something after the email is successfully sent
            } else {
                // Do something when the email fails to send
                //data.issue will be used to capture what field exactly caused the problem (eg they didn't fill out their name, or their email, or the captcha)
                //Should highlight the problem field in red so the customer can fix it
                //Need to handle issues:
                //name, email, phone, subject, message, captcha
                if (data.issue === 'name') {
                    errorList.push("name");
                }
                if (data.issue === 'contactMethod') {
                    errorList.push("contactMethod");
                }
                if (data.issue === 'email') {
                    errorList.push("email");
                }
                if (data.issue === 'phone') {
                    errorList.push("phone");
                }
                if (data.issue === 'subject') {
                    errorList.push("subject");
                }
                if (data.issue === 'message') {
                    errorList.push("message");
                }
                if (data.issue === 'captcha') {
                    errorList.push("captcha");
                }
                console.log(data.message);
                setModalMessage(`There was an error attempting to submit the form:\n${data.message}`);
                setShowModal(true);
                setError(errorList);
            }
        } catch (error) {
            // Handle error
            console.error('Error:', error);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (error.includes(name)) {
            setError(error.filter((e) => e !== name));
        }
        setFormData({ ...formData, [name]: value });
    }

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setFormData({ ...formData, captcha: value });
    }

    //<div className="g-recaptcha" data-sitekey="6LfXQ1YjAAAAABJM8C9YCa0phFfcd8QL_SAZFnb1"><div style="width: 304px; height: 78px;"><div><iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfXQ1YjAAAAABJM8C9YCa0phFfcd8QL_SAZFnb1&amp;co=aHR0cHM6Ly93d3cuaHVycmljYW5lbGlmdC5jb206NDQz&amp;hl=en&amp;v=MuIyr8Ej74CrXhJDQy37RPBe&amp;size=normal&amp;cb=cey2y04fbbaf" width="304" height="78" role="presentation" name="a-6azb40brfeg1" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea></div><iframe style="display: none;"></iframe></div>


    return (
        <div>
            <div>
                <Splash image={bgImg} text={text} />
            </div>
            <div>
                <div className="flex flex-col lg:flex-row my-10 items-center justify-center mx-auto" style={{ maxWidth: '80%' }}>
                    <div className="mx-auto w-full sm:w-1/3 md:w-1/4 lg:w-1/5">
                        <img className="object-contain drop-shadow-[20px_20px_10px_rgba(0,0,0,0.5)]" src={contactImage} />
                    </div>
                    <div className="mx-auto">
                        <h3 className="font-bold text-3xl">If you have a question, don't hesitate to contact us!</h3>
                        <p className="my-2">
                            Please fill out the form, and someone from Hurricane will get back to you as soon as possible.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col md:flex-row">
                                <div className="flex flex-col w-full justify-left items-left">
                                    <label htmlFor="name" className="form-label">Name {error.includes("name") && errorText}</label>
                                    <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.name} onChange={handleChange} id="name" name="name" />
                                </div>
                                <div className="flex flex-col w-full justify-left items-left">
                                    <label htmlFor="contactMethod" className="md:ml-3 form-label">Preferred Contact Method {error.includes("contactMethod") && errorText}</label>
                                    <select value={formData.contactMethod} onChange={handleChange} id="contactMethod" name="contactMethod" className="p-1 my-3 bg-gray-50 border border-gray-300 md:ml-3" >
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row">
                                <div className="flex flex-col w-full justify-left items-left">
                                    <label htmlFor="email" className="form-label">Email {error.includes("email") && errorText}</label>
                                    <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 md:mr-3" value={formData.email} onChange={handleChange} id="email" name="email" />
                                </div>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="phone" className="md:ml-3 form-label">Phone Number {error.includes("phone") && errorText}</label>
                                    <input type="tel" className="p-1 my-3 bg-gray-50 border border-gray-300 md:ml-3" value={formData.phone} onChange={handleChange} id="phone" name="phone" pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}" placeholder="555-555-5555" />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="subject" className="form-label">Subject {error.includes("subject") && errorText}</label>
                                    <input type="text" className="p-1 my-3 bg-gray-50 border border-gray-300" value={formData.subject} onChange={handleChange} id="subject" name="subject" />
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="message" className="form-label">Message {error.includes("message") && errorText}</label>
                                    <textarea type="text" className="p-1 my-3 bg-gray-50 border border-gray-300 w-full" value={formData.message} onChange={handleChange} id="message" name="message" rows="10"></textarea>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row">
                                <div>
                                    <div>
                                        {error.includes("captcha") && errorText}
                                        <ReCAPTCHA
                                            sitekey="6LfXQ1YjAAAAABJM8C9YCa0phFfcd8QL_SAZFnb1"
                                            onChange={onCaptchaChange}
                                        />
                                    </div>
                                    <br />
                                    <Button className="bg-gray-100 px-5" secondary outline>Submit</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <hr className="w-3/4 mx-auto my-10" />
            <div className="flex flex-col md:flex-col lg:flex-row items-center">
                <div className="m-auto">
                    <h4 className="text-2xl font-bold uppercase">Nova Scotia</h4>
                    <p>
                        Tel: 902-468-8518
                        <br />
                        188 Joseph Zatzman Drive
                        <br />
                        Dartmouth, NS, B3B 1N4
                    </p>
                    <iframe className="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d708.8134001486042!2d-63.60160093273923!3d44.714435581205834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5a2718e4e07215%3A0xfe357f4a39c482f2!2s188%20Joseph%20Zatzman%20Dr%2C%20Dartmouth%2C%20NS%20B3B%201N4!5e0!3m2!1sen!2sca!4v1666564037134!5m2!1sen!2sca" width="400" height="300" style={{ border: "0" }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
                <div className="m-auto">
                    <h4 className="text-2xl font-bold uppercase">Newfoundland</h4>
                    <p>
                        Tel: 709-745-7442
                        <br />
                        85 Glencoe Drive
                        <br />
                        Mount Pearl, NL, A1N 4S6
                    </p>
                    <iframe className="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1347.2735830862357!2d-52.846348401435925!3d47.51820945161888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0cbb7b6de835f3%3A0xceeb7c40975aa6f!2s85%20Glencoe%20Dr%2C%20Mount%20Pearl%2C%20NL%20A1N%204S6!5e0!3m2!1sen!2sca!4v1666564066901!5m2!1sen!2sca" width="400" height="300" style={{ border: "0" }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="m-auto">
                    <h4 className="text-2xl font-bold uppercase">New Brunswick</h4>
                    <p>
                        Tel: 506-657-5804
                        <br />
                        280 Grandview Ave
                        <br />
                        Saint John, NB, E2J 4N1
                    </p>
                    <iframe className="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2807.6604768524653!2d-66.0156765837707!3d45.27487365407633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ca7b3eb78e6285f%3A0xf7c9e9fbc28bd039!2s288%20Grandview%20Ave%2C%20Saint%20John%2C%20NB%20E2J%204M8!5e0!3m2!1sen!2sca!4v1666564084637!5m2!1sen!2sca" width="400" height="300" style={{ border: "0" }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            {showModal && modal}
        </div>
    );
}

export default ContactPage;