import { useState, useEffect, useRef } from 'react';
import Panel from './Panel';

function NavDropdown({ children, options, onChange }) {

    const [ isOpen, setIsOpen ] = useState(false);

    const dropdownRef = useRef();

    const handleClick = () => {
        setIsOpen((current) => !current);
    }

    const handleEnter = () => {
      if (window.innerWidth > 640) setIsOpen(true);
    }

    const handleExit = () => {
      if (window.innerWidth > 640) setIsOpen(false);
    }

    const handleSelect = (option) => {
      if (window.innerWidth < 640) setIsOpen(false);
      onChange(option);
    }

    useEffect(() => {
      const handler = (event) => {
        if (!dropdownRef.current) return;
        if (!dropdownRef.current.contains(event.target)) setIsOpen(false);
      };

      document.addEventListener('click', handler, true);

      return () => {
        document.removeEventListener('click', handler);
      };
    }, []);

    const renderedOptions = options.map((option, index) => {
        return (<div className={`hover:bg-sky-100 cursor-pointer p-1 ${
          index !== options.length - 1 && "border-b border-white"
        } ${index !== 0 && "not:first-child"}`} onClick={() => handleSelect(option)} key={option.value}>
            {option.label}
        </div>);
    });

    return (
        <div ref={dropdownRef} className="relative inline-block text-left" onMouseLeave={handleExit}>
            <Panel className="flex justify-between items-center cursor-pointer" onClick={handleClick} onMouseEnter={handleEnter}>
                {children}
            </Panel>
            {isOpen && (<Panel className="w-screen lg:w-max relative lg:absolute border border-black top-full bg-hurricane-orange">
                {renderedOptions}
            </Panel>)}
        </div>
    );
}

export default NavDropdown;