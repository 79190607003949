import Splash from "../components/Splash";
import liftLogo from "../images/logo-lift-white.png";
import bgImg from "../images/extra-pic.jpg";
import arthritisSociety from "../images/Arthritis-Society.png";
import dartmouthGeneralHospital from "../images/DartmouthGeneralHospital.png";
import feedNovaScotia from "../images/FeedNovaScotia.png";
import heartStroke from "../images/Heart-Stroke.png";
import kidSport from "../images/Kidsport.png";
import nsshof from "../images/NSSHOF.jpeg";
import qeii from "../images/QEII-Foundation.png";
import wardFive from "../images/Ward-5.png";

function AboutUsPage() {

    const text = <h1 className="text-7xl uppercase text-white font-bold pb-10" style={{ paddingRight: '5vw', paddingTop: '-15vh' }}>About Us</h1>

    return (
        <div>
            <Splash image={bgImg} text={text} />
            <div className="flex flex-col columns-1 mx-auto my-10 gap-8 items-start" style={{maxWidth: '50%'}}>
                <div>
                    <h1 className="uppercase font-bold text-3xl text-orange-500 pb-6">
                        About Us
                    </h1>
                    <p>
                        Established in 1991, Hurricane is aligned with recognized world class suppliers, Hyundai, Linde, Blue Giant and Loadlifter. We specialize in sales, leases, rentals, operator training, parts and service of all brands of lift trucks. Located in Dartmouth, Nova Scotia, we have branches in Saint John, New Brunswick and St. John's, Newfoundland. We are a full-service dealership, stocking over 22,000 SKU's in our parts department with next day delivery throughout Atlantic Canada. We operate 12+ fully stocked, GPS equipped service vans. Our service technicians use smart phone technology to open and clear calls, find parts within our network, and embed videos and pictures of lift truck deficiencies within your service quote. Our proprietary software allows us to monitor and manage some of the largest lift truck fleets in Atlantic Canada.
                    </p>
                </div>
                <div className="gap-5">
                    <h1 className="uppercase font-bold text-3xl text-orange-500 pb-6">
                        Our Mission
                    </h1>
                    <p>
                        We are a preferred lift truck dealer committed to providing premium equipment and product expertise. Our customers can count on our unique customer support systems to deliver the best overall value, service, and peace of mind throughout the life of every product.
                    </p>
                </div>
                <div className="gap-5">
                    <h1 className="uppercase font-bold text-3xl text-orange-500 pb-6">
                        Our Vision
                    </h1>
                    <p>
                        We will have the number one service and sales reputation in our industry by being exceptional partners, earning loyalty through extraordinary service.
                    </p>
                </div>
                <div className="gap-5">
                    <h1 className="uppercase font-bold text-3xl text-orange-500 pb-6">
                        Community Involvement
                    </h1>
                    <p className="pb-6">
                        At Hurricane Lift Truck, we are very proud of the contribution we have made to the community.
                        <br />
                        <br />
                        Over the years our employees have been actively involved in various organizations. Through our donation of time and resources, we are excited to continue in these efforts to support outstanding organizations:
                    </p>
                    <div className="flex grid grid-flow-row grid-cols-4 gap-20 items-center justify-center">
                        <img src={arthritisSociety} alt="Arthritis Society" />
                        <img src={dartmouthGeneralHospital} alt="Dartmouth General Hospital" />
                        <img src={feedNovaScotia} alt="Feed Nova Scotia" />
                        <img src={heartStroke} alt="Heart & Stroke Foundation" />
                        <img src={kidSport} alt="Kidsport" />
                        <img src={nsshof} alt="Nova Scotia Hall of Fame" />
                        <img src={qeii} alt="QEII Hospital" />
                        <img src={wardFive} alt="Ward Five" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;