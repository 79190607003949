import trainingImage from '../images/forklift_trainer.jpg';

function TrainingPage() {
    return (
        <div className="flex flex-col flex-wrap columns-1 mx-auto items-start justify-center my-10 w-4/5 xl:w-1/3">
            <h1 className="text-4xl md:text-7xl uppercase text-orange-600 font-bold pb-10 mx-auto">
                Training Courses
            </h1>
            <div>
                <h1 className="uppercase font-bold text-2xl">
                    Forklift Operator Training Near Halifax
                </h1>
                <br />
                <h1 className="uppercase font-bold">
                    Training Courses Offered:
                </h1>
                <br />
                <h1 className="uppercase font-bold">
                    Standard Certification <span className="text-gray-400">or Re-Certification:</span>
                </h1>
                <p>
                    Eight hour class for forklift operators who have never taken the course previously, or forklift operators with an expired 3-year certification.
                </p>
                <br />
            </div>
            <div>
                <h1 className="uppercase font-bold">
                    On-Site or Off Site Training:
                </h1>
                <p>
                    We provide a Forklift Operator Safety course, available at 188 Joseph Zatzman Drive, Dartmouth, Nova Scotia on a regular basis. We can also come to you for training for groups of 6 or larger. Please reach out for full details.
                    <br />
                    <br />
                    Thank you
                </p>
                <br />
                <img className="w-full" src={trainingImage} alt="Training" />
            </div>
        </div>
    );
}

export default TrainingPage;